<template>
  <div class="login-container">
    <div class="auth-body">
      <alert :message="warningMessage" variant="warning"/>
      <div class="dashboard-card login-card">
        <div class="image-container hidden-mobile">
          <login-icon />
        </div>

        <div class="form-container"  v-if="isOpenVerified === false">
          <h4>Welcome</h4>
          <validation-observer
            ref="loginForm"
          >
            <b-form
              class="auth-login-form mt-2"
              @submit.prevent="login"
            >
              <!-- email -->
              <b-form-group
                label="Email"
                label-for="login-email"
                class="mt-2"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  rules="required|email"
                >
                  <b-form-input
                    id="login-email"
                    v-model="loginData.userNameOrEmail"
                    :state="errors.length > 0 ? false:null"
                    name="login-email"
                    placeholder="example@illustrativedna.com"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- forgot password -->
              <b-form-group 
                label="Password"
                label-for="login-password"
                class="mt-2">
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-form-input
                      id="login-password"
                      v-model="loginData.password"
                      :state="errors.length > 0 ? false:null"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      name="login-password"
                      placeholder="············"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <div class="login-bottom mt-2 mb-2">
                <div class="forgot-password">
                  Forgot Password?
                  <b-link :to="{name:'forgotpassword'}">
                    <small>Reset it here</small>
                  </b-link>
                </div>
                <div class="login-btn">
                  <button class="lab-btn lab-btn-info lab-btn-md" type="submit">
                    <spinner-loader />
                    {{$t('Login')}}
                  </button>
                </div>
                
              </div>

            </b-form>
          </validation-observer>
        </div>

        <div class="verify-container" v-else>
          <div class="verify-info">
            <p class="info-desc">
              For your security, enter the verification code sent to your email address {{ loginData.userNameOrEmail }} in the field below.
            </p>
            <b-alert variant="danger" show>
              <div class="alert-body">
                <span>Since the time has expired, you must receive a verification code again via the Send New Code link.</span>
              </div>
            </b-alert>
          </div>

          <validation-observer ref="loginForm">
            <b-form
              class="auth-login-form mt-2"
              @submit.prevent="login"
            >
              <validation-provider
                #default="{ errors }"
                name="verifyCode"
                rules="required"
              >
                <b-form-input
                  id="verifyCode"
                  v-model="loginData.verifyCode"
                  :state="errors.length > 0 ? false:null"
                  name="verifyCode"
                  maxlength="6"
                  mixlength="6"
                  placeholder="Enter verification Code"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
              <div class="d-flex justify-content-between mt-2 mb-2">
                <div class="remaining-time">
                  <div>
                    <span>Remaining Time: <b><TimerCounter v-if="isOpenVerified" :endDate="timerDate"/></b></span>
                  </div>
                  <div class="send-again" @click="sendAgain()">Send new code</div>
                </div>
                <button class="lab-btn lab-btn-info lab-btn-md" type="submit">
                  <spinner-loader />
                  {{$t('Verify')}}
                </button>
              </div>
          </b-form>
          </validation-observer>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import useJwt from '@/auth/jwt/useJwt'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import { getHomeRouteForLoggedInUser } from '@/auth/utils'

import LoginIcon from '@/layouts/icons/store/LoginIcon.vue'
import TimerCounter from '../../components/TimerCounter.vue'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    TimerCounter,
    LoginIcon,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      warningMessage: null,
      loginData: {
        userNameOrEmail: '',
        password: '',
        verifyCode: '',
        rememberMe: false,
      },
      isOpenVerified: false,
      timerDate: null,
      sideImg: require('@/assets/images/pages/login-v2.png'),
      required,
      email,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (this.$store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/login-v2.png')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  methods: {
    login() {
      this.warningMessage = null;
      
      this.$refs.loginForm.validate().then(success => {
        if (success) {
          this.$store.commit('setLoader', true)
          useJwt.login(this.loginData)
            .then(response => {
              if (response.statusCode === 200) {
                const userData = response.result
                useJwt.setToken(response.result.token)
                useJwt.setRefreshToken(response.result.token)
                useJwt.setUserData(userData)

                localStorage.setItem('g25Checkout', null);
                localStorage.setItem('traitCheckout', null);
                this.$store.commit('account/setIsLoggedIn', true);
                
                this.$router.push(getHomeRouteForLoggedInUser(userData.userRole));
              } else if (response.statusCode === 1025) {
                this.isOpenVerified = true;
                let timerDate = new Date();
                timerDate.setMinutes(timerDate.getMinutes() + 5); // timestamp
                timerDate = new Date(timerDate); // Date object
                this.timerDate = timerDate;
              } else if (response.statusCode === 1026) {
                this.warningMessage = 'Verification code is not valid';
              } else {
                this.warningMessage = response.message;
              }
              this.$store.commit('setLoader', false)
            })
        }
      })
    },
    logout() {
      // Remove userData from localStorage
      // ? You just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
      localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
      localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)

      // Remove userData from localStorage
      localStorage.removeItem('userData')
      localStorage.setItem('g25Checkout', null);
      localStorage.setItem('traitCheckout', null);
    },

    sendAgain() {
      this.loginData.verifyCode = '';
      this.login();
    },
  },
  created() {
    this.logout();
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>

<style lang="scss" scoped>
.login-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  .auth-body {
    padding: 2.5rem;
    max-width: 1020px;
  }
 .login-card {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 2rem 1.5rem;
    .image-container {
      margin-right: 1rem;
      padding: 2rem;
    }
    h4 {
      color: var(--lab-black);
    }

    .form-container {
      width: 500px;

      .forgot-password {
        font-size: 0.8rem;
        a {
          color: var(--lab-minted-elegance-color);
        }
      }
    }
  }

  .create-account {
    color: var(--lab-steel);
    font-size: 0.8rem;
    text-align: center;
    margin-top: 3rem;
    a {
      color: var(--lab-minted-elegance-color);
    }
  }

  .footer-container {
    .btn {
      padding: 0.6rem !important;
      &:not(:last-child) {
        margin-right: 1rem;
      }
    }
    .btn-linkedin {
      background-color: #1608d8;
      border-color: #fff;
      border: none;
    }
    .btn-reddit {
      background-color: #FF4500;
      border-color: #fff;
      border: none;
    }
  }

  .verify-container {
    .info-desc, .alert-body {
      font-size: 0.85rem;
    }
    .remaining-time {
      span {
        color: var(--lab-steel);
        font-size: 0.9rem;
      }

      .send-again {
        color: var(--lab-minted-elegance-color);
        cursor: pointer;
      }
    }
  }

  .input-group {
    box-shadow: none !important;
  }
  .form-control:focus {
    border: 2px solid var(--lab-minted-elegance-color);
  }

  .login-bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

@media screen and (max-width: 769px) {
  .form-container {
    width: 300px !important;
  }
  .login-bottom {
    display: block !important;
  }
  .login-btn {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    button {
      width: 150px;
    }
  }
}
</style>
